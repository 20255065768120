import React from 'react';
import PropTypes from 'prop-types';

const BRANDS = {
  GP: {
    logoUrl: 'https://firebasestorage.googleapis.com/v0/b/garage-project-matrix.appspot.com/o/hosting%2Fgarage-project-signature-logo.png?alt=media&token=7e611adc-2dca-4026-b3d7-05c0f1c112f8',
    instagramUrl: 'https://www.instagram.com/garageproject',
    facebookUrl: 'https://www.facebook.com/garageproject',
    youtubeUrl: 'https://www.youtube.com/user/NZGarageProject',
    twitterUrl: 'https://twitter.com/garage_project',
    untappdUrl: 'https://untappd.com/garageproject',
    webstoreUrl: {
      NZ: 'https://garageproject.co.nz/',
      AU: 'https://garageproject.com.au/',
    },
  },
  DW: {
    logoUrl: 'https://firebasestorage.googleapis.com/v0/b/garage-project-matrix.appspot.com/o/hosting%2Fdirty-water-signature-logo.png?alt=media&token=0b26c443-7a0e-41d2-bc7d-513cb0e9d12f',
    instagramUrl: 'https://www.instagram.com/dirtywaterseltzer',
    facebookUrl: 'https://www.facebook.com/dirtywaterseltzer/',
    youtubeUrl: 'https://youtu.be/19NhnbZ099M',
    untappdUrl: 'https://untappd.com/garageproject/beer?q=Dirty%20Water',
    webstoreUrl: {
      NZ: 'https://dirtywater.co.nz/',
      AU: 'https://dirtywater.com.au/',
    },
  },
};

const BADGES = {
  garageProject: {
    src: 'https://firebasestorage.googleapis.com/v0/b/garage-project-matrix.appspot.com/o/hosting%2Fgarage-project-signature-logo.png?alt=media&token=7e611adc-2dca-4026-b3d7-05c0f1c112f8',
    href: {
      NZ: 'https://garageproject.co.nz/',
      AU: 'https://garageproject.com.au/',
    },
    alt: 'Garage Project',
  },
  pridePledge: {
    src: 'https://firebasestorage.googleapis.com/v0/b/garage-project-matrix.appspot.com/o/hosting%2FPridePledge25.png?alt=media&token=555547aa-6ff6-4ab0-8ff8-d6d7dedc4063',
    href: 'https://pridepledge.co.nz/',
    alt: 'Pride Pledge',
  },
  ekosZeroCarbon: {
    src: 'https://firebasestorage.googleapis.com/v0/b/garage-project-matrix.appspot.com/o/hosting%2FCarbon-Zero_New-Logo.png?alt=media&token=b6341bf3-065e-4145-a3c7-bd3d665ae2a7',
    href: 'https://ekos.co.nz/zero-carbon-climate-positive',
    alt: 'Ekos Zero Carbon Certified',
  },
  sustainableBusinessNetwork: {
    src: 'https://firebasestorage.googleapis.com/v0/b/garage-project-matrix.appspot.com/o/hosting%2Fsustainable-business-network.png?alt=media&token=827e126d-85d9-43b8-91cc-1d7ed94ae553',
    href: 'https://sustainable.org.nz/',
    alt: 'Sustainable Business Network Member',
  },
  millionMetresStreamsProject: {
    src: 'https://firebasestorage.googleapis.com/v0/b/garage-project-matrix.appspot.com/o/hosting%2Fmillion-metres-streams-project.png?alt=media&token=2c133f82-a324-49cf-83bc-d4d9605ff41e',
    href: 'https://millionmetres.org.nz/',
    alt: 'Million Metres Streams Project',
  },
  livingWageEmployer: {
    src: 'https://firebasestorage.googleapis.com/v0/b/garage-project-matrix.appspot.com/o/hosting%2Fliving-wage-employer.png?alt=media&token=3cc71a89-623b-4a8e-a5c0-988817e81dcf',
    href: 'https://www.livingwage.org.nz/about_lv2019',
    alt: 'Accredited Living Wage Employer',
  },
  mentalHealthResponder: {
    src: 'https://firebasestorage.googleapis.com/v0/b/garage-project-matrix.appspot.com/o/hosting%2Fcoliberate-mental-health.png?alt=media&token=36d743e6-86a8-43d7-99c7-1b9da38aa3f1',
    href: 'https://www.coliberate.co.nz/mental-health-first-response/',
    alt: 'CoLiberate Mental Health First Responder',
  },
  certifiedBeerServer: {
    src: 'https://firebasestorage.googleapis.com/v0/b/garage-project-matrix.appspot.com/o/hosting%2Fcicerone-certified-beer-server-128.png?alt=media&token=0178f476-d4d6-4837-ba07-7070e2bd10a5',
    href: 'https://www.cicerone.org/int-en/certifications/certified-beer-server',
    alt: 'Certified Beer Server',
  },
  certifiedCicerone: {
    src: 'https://firebasestorage.googleapis.com/v0/b/garage-project-matrix.appspot.com/o/hosting%2Fcertified-cicerone-128.png?alt=media&token=1aeb86ca-2139-44b1-a84a-038ba4d0a983',
    href: 'https://www.cicerone.org/int-en/certifications/certified-cicerone',
    alt: 'Certified Cicerone®',
  },
  advancedCicerone: {
    src: 'https://firebasestorage.googleapis.com/v0/b/garage-project-matrix.appspot.com/o/hosting%2Fadvanced-cicerone-certification-128.png?alt=media&token=9fa46f2a-3179-4640-909c-7fad7f69db5c',
    href: 'https://www.cicerone.org/int-en/certifications/advanced-cicerone',
    alt: 'Advanced Cicerone®',
  },
  masterCicerone: {
    src: 'https://firebasestorage.googleapis.com/v0/b/garage-project-matrix.appspot.com/o/hosting%2Fmaster-cicerone-certification-128.png?alt=media&token=d7618654-624d-4d4d-a58b-69cfa8537e3c',
    href: 'https://www.cicerone.org/int-en/certifications/master-cicerone',
    alt: 'Master Cicerone®',
  },
};

const Signature = ({
  name, title, pronouns, phone, email, brand, country, extras,
}) => {
  const brandConfig = BRANDS[brand];
  const {
    logoUrl, instagramUrl, facebookUrl, youtubeUrl, twitterUrl, untappdUrl,
  } = brandConfig;
  const webstoreUrl = brandConfig.webstoreUrl[country];

  return (
    <div style={{ fontFamily: 'Monaco, sans-serif' }}>
      <table cellPadding="0" cellSpacing="0">
        <tbody>
          <tr>
            <td
              style={{
                borderRight: '1px solid #000000', paddingRight: '15px', fontSize: '1pt', verticalAlign: 'middle', fontFamily: 'Monaco',
              }}
              valign="middle"
            >
              <table style={{ fontFamily: 'Monaco' }} cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr>
                    <td
                      style={{
                        height: '60px', fontFamily: 'Monaco', verticalAlign: 'top', textAlign: 'center',
                      }}
                      valign="middle"
                    >
                      <a href={webstoreUrl} target="_blank" rel="noreferrer">
                        <img alt="Logo" style={{ height: '60px', width: 'auto', border: '0' }} height="60" border="0" src={logoUrl} />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td
              style={{
                fontFamily: 'Monaco', paddingLeft: '15px', textAlign: 'left', verticalAlign: 'top',
              }}
              valign="top"
            >
              <table style={{ fontFamily: 'Monaco', marginRight: 'auto', marginLeft: 0 }} cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr>
                    <td
                      style={{
                        fontFamily: 'Monaco', verticalAlign: 'top', textAlign: 'left', lineHeight: '14pt', paddingBottom: '5px',
                      }}
                      valign="top"
                      align="left"
                    >
                      <span style={{
                        fontFamily: 'Monaco', fontSize: '10pt', fontWeight: 'bold', color: '#000000',
                      }}
                      >
                        {name}
                      </span>
                      <br />
                      <span style={{ fontFamily: 'Monaco', fontSize: '9pt', color: '#000000' }}>{title}</span>
                      <br />
                      {pronouns && <span style={{ fontFamily: 'Monaco', fontSize: '8pt', color: '#808080' }}>{`(${pronouns})`}</span>}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontFamily: 'Monaco', verticalAlign: 'top', padding: 0, textAlign: 'left', lineHeight: '12pt',
                      }}
                      valign="top"
                      align="left"
                    >
                      {phone && (
                        <>
                          <a
                            style={{
                              fontFamily: 'Monaco', fontSize: '9pt', color: 'rgb(51,136,204)', textDecoration: 'none',
                            }}
                            href={`tel:${phone}`}
                          >
                            {phone}
                          </a>
                          <br />
                        </>
                      )}

                      <span style={{ fontFamily: 'Monaco', fontSize: '9pt', color: 'rgb(51,136,204)' }}>
                        <a
                          style={{
                            fontFamily: 'Monaco', fontSize: '9pt', color: 'rgb(51,136,204)', textDecoration: 'none', whiteSpace: 'nowrap',
                          }}
                          href={`mailto:${email}`}
                        >
                          {email}
                        </a>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontFamily: 'Monaco', height: '35px', verticalAlign: 'bottom', textAlign: 'left', paddingBottom: '6px',
                      }}
                      valign="middle"
                      align="left"
                    >
                      <span>
                        <a href={instagramUrl} target="_blank" rel="noreferrer">
                          <img
                            border="0"
                            width="20"
                            alt="Instagram"
                            style={{
                              border: 0, height: '20px', width: '20px', marginRight: '8px',
                            }}
                          // https://www.flaticon.com/free-icon/instagram_1384015
                            src="https://firebasestorage.googleapis.com/v0/b/garage-project-matrix.appspot.com/o/hosting%2Finstagram-128.png?alt=media&token=464ae945-8da4-4a34-bfc9-b983fb4142c3"
                          />
                        </a>
                      </span>
                      <span>
                        <a href={facebookUrl} target="_blank" rel="noreferrer">
                          <img
                            border="0"
                            width="20"
                            alt="Facebook"
                            style={{
                              border: 0, height: '20px', width: '20px', marginRight: '8px',
                            }}
                          // https://www.flaticon.com/free-icon/facebook_1384005?
                            src="https://firebasestorage.googleapis.com/v0/b/garage-project-matrix.appspot.com/o/hosting%2Ffacebook-128.png?alt=media&token=4a835d8d-5f8d-47fe-aa9f-aa7d51d1b5bb"
                          />
                        </a>
                      </span>
                      <span>
                        <a href={youtubeUrl} target="_blank" rel="noreferrer">
                          <img
                            border="0"
                            width="20"
                            alt="YouTube"
                            style={{
                              border: 0, height: '20px', width: '20px', marginRight: '8px',
                            }}
                          // https://www.flaticon.com/free-icon/youtube_1384012
                            src="https://firebasestorage.googleapis.com/v0/b/garage-project-matrix.appspot.com/o/hosting%2Fyoutube-128.png?alt=media&token=c5e67c9b-d539-4df9-82fd-926f82278b3e"
                          />
                        </a>
                      </span>
                      {twitterUrl && (
                      <span>
                        <a href={twitterUrl} target="_blank" rel="noreferrer">
                          <img
                            border="0"
                            width="20"
                            alt="Twitter"
                            style={{
                              border: 0, height: '20px', width: '20px', marginRight: '8px',
                            }}
                          // https://www.flaticon.com/free-icon/twitter_1384017
                            src="https://firebasestorage.googleapis.com/v0/b/garage-project-matrix.appspot.com/o/hosting%2Ftwitter-128.png?alt=media&token=567abe61-ca33-4d97-932f-79eb888c6d0e"
                          />
                        </a>
                      </span>
                      )}
                      <span>
                        <a href={untappdUrl} target="_blank" rel="noreferrer">
                          <img
                            border="0"
                            width="20"
                            alt="Untappd"
                            style={{
                              border: 0, height: '20px', width: '20px', marginRight: '8px',
                            }}
                            src="https://firebasestorage.googleapis.com/v0/b/garage-project-matrix.appspot.com/o/hosting%2Funtappd-128.png?alt=media&token=2c7d65c2-df68-4a35-b73d-258033c198e9"
                          />
                        </a>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      {!!extras.filter((key) => key !== 'auSalesPortal').length && (
        <div style={{
          paddingTop: '15px',
          maxWidth: '400px',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
        }}
        >
          {Object.entries(BADGES).map(([key, badge]) => (
            extras.includes(key) ? (
              <span key={key}>
                <a
                  href={typeof badge.href === 'string' ? (
                    badge.href
                  ) : (
                    badge.href[country]
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    border="0"
                    height="45"
                    alt={badge.alt}
                    style={{
                      border: 0,
                      height: '45px',
                      marginRight: '10px',
                    }}
                    src={badge.src}
                  />
                </a>
              </span>
            ) : null
          ))}
        </div>
      )}
      {extras.includes('auSalesPortal') && (
        <span>
          <img
            border="0"
            height="80"
            alt="Australia Online Ordering Portal"
            style={{
              border: 0, height: '80px', marginTop: '15px',
            }}
            src="https://firebasestorage.googleapis.com/v0/b/garage-project-matrix.appspot.com/o/hosting%2Fau-sales-portal-banner.png?alt=media&token=5f5506b2-a573-45d2-b5d1-a95a60cfb9e4"
          />
        </span>
      )}
    </div>
  );
};

export default Signature;

Signature.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  pronouns: PropTypes.string,
  phone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  email: PropTypes.string.isRequired,
  brand: PropTypes.oneOf(['GP', 'DW']).isRequired,
  country: PropTypes.oneOf(['NZ', 'AU']).isRequired,
  extras: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Signature.defaultProps = {
  pronouns: null,
  phone: null,
};
